/// Creates a sliding transition by translating the element horizontally or vertically.
/// @param {Keyword} $state [in] - State to transition to.
/// @param {Keyword} $direction [left] - Direction to slide to. Can be `up`, `right`, `down`, or `left`.
/// @param {Length} $amount [100%] - Length of the slide as a percentage value.
/// @param {Boolean} $fade [false] - Set to `true` to fade the element in or out simultaneously.
/// @param {Duration} $duration [null] - Length (speed) of the transition.
/// @param {Keyword|Function} $timing [null] - Easing of the transition.
/// @param {Duration} $delay [null] - Delay in seconds or milliseconds before the transition starts.
@mixin mui-slide (
  $state: in,
  $direction: left,
  $amount: 100%,
  $fade: map-get($motion-ui-settings, slide-and-fade),
  $duration: null,
  $timing: null,
  $delay: null
) {
  $slide: slide($state, $direction, $amount);

  // CSS Output
  @include transition-start($state) {
    @include transition-basics($duration, $timing, $delay);
    @include -mui-keyframe-get($slide, 0);

    @if $fade {
      transition-property: transform, opacity;
      opacity: if($state == in, 0, 1);
    } @else {
      transition-property: transform, opacity;
    }

    backface-visibility: hidden;
  }

  @include transition-end($state) {
    @include -mui-keyframe-get($slide, 100);

    @if $fade {
      opacity: if($state == in, 1, 0);
    }
  }
}
